import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  const [links, setLinks] = useState([]);
  const [newLink, setNewLink] = useState({ name: '', url: '', description: '' });

  useEffect(() => {
    fetchLinks();
  }, []);

  const fetchLinks = async () => {
    try {
      const response = await axios.get('/api/links');
      setLinks(response.data);
    } catch (error) {
      console.error('Error fetching links:', error);
    }
  };

  const createLink = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/links', newLink);
      setNewLink({ name: '', url: '', description: '' });
      fetchLinks();
    } catch (error) {
      console.error('Error creating link:', error);
    }
  };

  const deleteLink = async (id) => {
    if (!id) {
      console.error('Invalid link ID');
      return;
    }
  
    try {
      console.log('Attempting to delete link with ID:', id);
      const response = await axios.delete(`/api/links/${id}`);
      console.log('Delete response:', response);
      fetchLinks(); // Refrescar la lista de enlaces
    } catch (error) {
      console.error('Error deleting link:', error);
      if (error.response) {
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
      }
    }
  };

  return (
    <div className="App">
      <h1>Access List Management</h1>
      
      <form onSubmit={createLink}>
        <input
          type="text"
          placeholder="Name"
          value={newLink.name}
          onChange={(e) => setNewLink({...newLink, name: e.target.value})}
        />
        <input
          type="text"
          placeholder="URL"
          value={newLink.url}
          onChange={(e) => setNewLink({...newLink, url: e.target.value})}
        />
        <input
          type="text"
          placeholder="Description"
          value={newLink.description}
          onChange={(e) => setNewLink({...newLink, description: e.target.value})}
        />
        <button type="submit">Add Link</button>
      </form>

      <ul>
        {links.map((link) => (
          <li key={link.id}>
            {link.name} - <a href={link.url}>{link.url}</a>
            <p>{link.description}</p>
            <button onClick={() => deleteLink(link.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;